import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Card, CardContent, Typography, CardMedia, CircularProgress, Link, Box } from '@mui/material';
import apiClient from '../helpers/apiClient';

const VisitDetail = () => {
    const { id } = useParams();
    const [visit, setVisit] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchVisit = async () => {
        setLoading(true);
        try {
            const response = await apiClient.get(`/admin/visits/getVisit/${id}`);
            setVisit(response.data);
        } catch (error) {
            console.error('Error fetching visit:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        document.title = 'Visit Detail | Perfect Store Admin';
        fetchVisit();
    }, [id]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!visit) {
        return <Typography variant="h6">Visit not found</Typography>;
    }

    const renderImage = (image) => (
        image ? (
            <Link href={image} target="_blank" rel="noopener" sx={{ display: 'block' }}>
                <CardMedia component="img" src={image} alt="Image" sx={{ height: 'auto', width: '100%', my: 2, borderRadius: 0 }} />
            </Link>
        ) : (
            <Box sx={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px dashed #ccc', borderRadius: 2, my: 2 }}>
                <Typography variant="body2">No image found</Typography>
            </Box>
        )
    );

    const renderValue = (value) => value ? value : 'N/A';

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>


            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2, p: 2, border: '1px solid #C5C5C5', borderRadius: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '24px', color: '#2C353A', mb: 1 }}>Perfect Store Score</Typography>
                <Box sx={{ position: 'relative', display: 'inline-flex', width: 200, height: 200 }}>
                    <CircularProgress variant="determinate" value={visit.perfect_score} size={200} thickness={5} sx={{ position: 'absolute' }} />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            width: 200,
                            height: 200,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingBottom: '20px'
                        }}
                    >
                        <Typography variant="h5" component="div" color="textSecondary"
                            sx={{ fontSize: '48px' }}
                        >{`${visit.perfect_score}`}</Typography>
                    </Box>
                </Box>
            </Box>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Card sx={{ border: '1px solid #C5C5C5', borderRadius: 2, boxShadow: 0 }}>
                        <CardContent>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, fontSize: '24px', color: '#2C353A', mb: '20px' }}>Market Information</Typography>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A', pb: '10px' }}><strong>Market:</strong> {renderValue(visit.market)}</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A', pb: '10px' }}><strong>Type of Visit:</strong> {renderValue(visit.type_of_visit)}</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A', pb: '10px' }}><strong>Store Name:</strong> {renderValue(visit.store_name)}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A', pb: '10px' }}><strong>Category:</strong> {renderValue(visit.category)}</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A', pb: '10px' }}><strong>Visited By:</strong> {renderValue(visit.user_name)}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card sx={{ border: '1px solid #C5C5C5', borderRadius: 2, mb: 2, boxShadow: 0 }}>
                        <CardContent>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, fontSize: '24px', color: '#2C353A', mb: '20px' }}>Perfect Score Info</Typography>
                            <Box sx={{ background: '#004A6D0D', borderRadius: 2, p: 2, mb: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A' }}>
                                            Planogram Compliance:
                                            <span style={{ color: '#0094D9' }}> {renderValue(visit.planogram_compliance)}</span>
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A', mt: 1 }}>
                                            Planogram Feedback:
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '16px', color: '#2C353A' }}>
                                            {renderValue(visit.planogram_feedback)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {renderImage(visit.planogram_image)}
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ background: '#004A6D0D', borderRadius: 2, p: 2, mb: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A' }}>
                                            SOS Targets:
                                            <span style={{ color: '#0094D9' }}> {renderValue(visit.sos_targets)}</span>
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A', mt: 1 }}>
                                            SOS Feedback:
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '16px', color: '#2C353A' }}>
                                            {renderValue(visit.sos_feedback)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {renderImage(visit.sos_image)}
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ background: '#004A6D0D', borderRadius: 2, p: 2, mb: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A' }}>
                                            Must Stock List:
                                            <span style={{ color: '#0094D9' }}> {renderValue(visit.must_stock_list)}</span>
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A', mt: 1 }}>
                                            MSL Feedback:
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '16px', color: '#2C353A' }}>
                                            {renderValue(visit.msl_feedback)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {renderImage(visit.msl_image)}
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ background: '#004A6D0D', borderRadius: 2, p: 2, mb: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A' }}>
                                            On Shelf Availability:
                                            <span style={{ color: '#0094D9' }}> {renderValue(visit.on_shelf_availability)}</span>
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A', mt: 1 }}>
                                            OSA Feedback:
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '16px', color: '#2C353A' }}>
                                            {renderValue(visit.osa_feedback)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {renderImage(visit.osa_image)}
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>



                <Grid item xs={12}>
                    <Card sx={{ border: '1px solid #C5C5C5', borderRadius: 2, boxShadow: 0 }}>
                        <CardContent>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, fontSize: '24px', color: '#2C353A', mb: '20px' }}>Additional Information</Typography>
                            <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px' }}>
                                Rainbow 170G Price:
                                <span style={{ fontWeight: 400, color: '#2C353A' }}> {renderValue(visit.hero_sku_I_price)}</span>
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px' }}>
                                Rainbow (12x170g) Price:
                                <span style={{ fontWeight: 400, color: '#2C353A' }}> {renderValue(visit.hero_sku_II_price)}</span>
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px' }}>
                                Rainbow Cardamom 170g Price:
                                <span style={{ fontWeight: 400, color: '#2C353A' }}> {renderValue(visit.hero_sku_III_price)}</span>
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px' }}>
                                Rainbow SCM 397g Price:
                                <span style={{ fontWeight: 400, color: '#2C353A', mb: '20px' }}> {renderValue(visit.hero_sku_IV_price)}</span>
                            </Typography>


                            <Box sx={{ background: '#004A6D0D', borderRadius: 2, p: 2, mb: 2, mt: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A' }}>
                                            SKU Name Price:
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '16px', color: '#2C353A', mb: 2 }}>
                                            {renderValue(visit.sku_name_price)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {renderImage(visit.sku_name_price_image, "SKU Name Price Image")}
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{ background: '#004A6D0D', borderRadius: 2, p: 2, mb: 2, mt: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A' }}>
                                            Competitor Activity:
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '16px', color: '#2C353A', mb: 2 }}>
                                            {renderValue(visit.competitor_activity)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {renderImage(visit.competitor_activity_image, "Competitor Activity Image")}
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{ background: '#004A6D0D', borderRadius: 2, p: 2, mb: 2, mt: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A' }}>
                                            Secondary Displays:
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '16px', color: '#2C353A', mb: 2 }}>
                                            {renderValue(visit.secondary_displays)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {renderImage(visit.secondary_displays_image, "Secondary Displays Image")}
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{ background: '#004A6D0D', borderRadius: 2, p: 2, mb: 2, mt: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px', color: '#2C353A' }}>
                                            Others:
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '16px', color: '#2C353A', mb: 2 }}>
                                            {renderValue(visit.others)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {renderImage(visit.others_image, "Others Image")}
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </Container>
    );
};

export default VisitDetail;