import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_API_URL } from '../constants';

const apiClient = axios.create({
    baseURL: BASE_API_URL,
});

apiClient.interceptors.request.use(
    async (config) => {
        const adminToken = Cookies.get('adminToken');
        if (adminToken) {
            config.headers['X-Admin-Token'] = adminToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default apiClient;