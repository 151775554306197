import React, { useState, useEffect } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import apiClient from '../helpers/apiClient';

const Leaderboard = () => {
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [sortConfig, setSortConfig] = useState({
        key: 'user_name',
        direction: 'asc'
    });

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Leaderboard | Perfect Store Admin';
        const fetchLeaderboardData = async () => {
            try {
                const response = await apiClient.get('/admin/dashboard/getLeaderboard');
                setLeaderboardData(response.data.leaderboard);
            } catch (error) {
                console.error('Error fetching leaderboard data:', error);
            }
        };
        fetchLeaderboardData();
    }, []);

    const handleSort = (column) => {
        let direction = 'asc';
        if (sortConfig.key === column && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key: column, direction: direction });
    };

    const sortedLeaderboardData = React.useMemo(() => {
        let sortableItems = [...leaderboardData];
        sortableItems.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
        return sortableItems;
    }, [sortConfig, leaderboardData]);

    const handleRowClick = (userName) => {
        navigate(`/visits?username=${userName}`);
    };

    return (
        <Container maxWidth={false} sx={{ mt: 4 }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sortDirection={sortConfig.key === 'user_name' ? sortConfig.direction : false}>
                                <TableSortLabel
                                    active={sortConfig.key === 'user_name'}
                                    direction={sortConfig.key === 'user_name' ? sortConfig.direction : 'asc'}
                                    onClick={() => handleSort('user_name')}
                                >
                                    Employee
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={sortConfig.key === 'visit_count' ? sortConfig.direction : false}>
                                <TableSortLabel
                                    active={sortConfig.key === 'visit_count'}
                                    direction={sortConfig.key === 'visit_count' ? sortConfig.direction : 'asc'}
                                    onClick={() => handleSort('visit_count')}
                                >
                                    Visits
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedLeaderboardData.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    No data found
                                </TableCell>
                            </TableRow>
                        ) : (
                            sortedLeaderboardData.map((row, index) => (
                                <TableRow key={index} onClick={() => handleRowClick(row.user_name)} style={{ cursor: 'pointer' }}>
                                    <TableCell>{row.user_name}</TableCell>
                                    <TableCell>{row.visit_count}</TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default Leaderboard;
