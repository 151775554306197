import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, TextField, Select, MenuItem, Grid, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import apiClient from '../helpers/apiClient';
import { useToast } from '../providers/ToastProvider';

const Visits = () => {
    const [visits, setVisits] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sortModel, setSortModel] = useState([
        {
            field: 'created_at',
            sort: 'desc',
        },
    ]);
    const [searchQuery, setSearchQuery] = useState('');
    const [marketFilter, setMarketFilter] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [typeOfVisitFilter, setTypeOfVisitFilter] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [visitToDelete, setVisitToDelete] = useState(null);
    const [startDate, endDate] = dateRange;

    const navigate = useNavigate();
    const location = useLocation();
    const notify = useToast();

    useEffect(() => {
        document.title = 'Visits | Perfect Store Admin';
        const params = new URLSearchParams(location.search);
        const username = params.get('username');
        setSearchQuery(username);
    }, [location, navigate]);

    const fetchVisits = async () => {
        const params = new URLSearchParams(location.search);
        const username = params.get('username');
        setLoading(true);
        try {
            const response = await apiClient.get('/admin/visits/getVisits', {
                params: {
                    page: page + 1,
                    perPage: pageSize,
                    sortBy: sortModel[0]?.field,
                    sortOrder: sortModel[0]?.sort,
                    search: searchQuery,
                    market: marketFilter,
                    category: categoryFilter,
                    typeOfVisit: typeOfVisitFilter,
                    startDate: startDate ? startDate.toISOString().split('T')[0] : '',
                    endDate: endDate ? endDate.toISOString().split('T')[0] : '',
                    username,
                },
            });
            if (response.data.data && response.data.total) {
                setVisits(response.data.data);
                setRowCount(response.data.total);
            } else {
                setVisits([]);
                setRowCount(0);
            }
        } catch (error) {
            console.error('Error fetching visits:', error);
            setVisits([]);
            setRowCount(0);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchVisits();
    }, [page, pageSize, sortModel, searchQuery, marketFilter, categoryFilter, typeOfVisitFilter, dateRange, location]);

    const handleDeleteVisit = async () => {
        try {
            await apiClient.post(`/admin/visits/deleteVisit/${visitToDelete}`);
            notify('Visit deleted successfully', { type: 'success' });
            fetchVisits(); // Re-fetch visits after deletion
            handleCloseDialog(); // Close the dialog
        } catch (error) {
            console.error('Error deleting visit:', error);
        }
    };

    const handleOpenDialog = (visitId) => {
        setVisitToDelete(visitId);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setVisitToDelete(null);
        setDialogOpen(false);
    };

    const handleDownload = async () => {
        const params = new URLSearchParams({
            sortBy: sortModel[0]?.field,
            sortOrder: sortModel[0]?.sort,
            search: searchQuery ?? '',
            market: marketFilter ?? '',
            category: categoryFilter ?? '',
            typeOfVisit: typeOfVisitFilter ?? '',
            startDate: startDate ? startDate.toISOString().split('T')[0] : '',
            endDate: endDate ? endDate.toISOString().split('T')[0] : '',
            download: true,
        });
        const username = new URLSearchParams(location.search).get('username');
        if (username) {
            params.append('username', username);
        }
        try {
            const response = await apiClient.get(`/admin/visits/getVisits?${params.toString()}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'visits.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error downloading visits:', error);
        }
    };

    const columns = [
        { field: 'created_at', headerName: 'Visit Date', flex: 1, valueFormatter: (params) => params ? format(parseISO(params), 'yyyy-MM-dd') : '' },
        { field: 'market', headerName: 'Market', flex: 1 },
        { field: 'category', headerName: 'Category', flex: 1 },
        { field: 'type_of_visit', headerName: 'Type of Visit', flex: 1 },
        { field: 'store_name', headerName: 'Store Name', flex: 1 },
        { field: 'user_name', headerName: 'Employee Name', flex: 1 },
        { field: 'perfect_score', headerName: 'Perfect Score', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="error"
                    onClick={(event) => {
                        event.stopPropagation();
                        handleOpenDialog(params.row.id);
                    }}
                >
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <Container maxWidth={false} sx={{ mt: 4 }}>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={3}>
                    <Select
                        fullWidth
                        value={marketFilter}
                        onChange={(e) => setMarketFilter(e.target.value)}
                        displayEmpty
                        variant="outlined"
                    >
                        <MenuItem value="">All Markets</MenuItem>
                        <MenuItem value="UAE">UAE</MenuItem>
                        <MenuItem value="KSA">KSA</MenuItem>
                        <MenuItem value="OMN">OMN</MenuItem>
                        <MenuItem value="QTR">QTR</MenuItem>
                        <MenuItem value="BAH">BAH</MenuItem>
                        <MenuItem value="KWT">KWT</MenuItem>
                        <MenuItem value="MOR">MOR</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Select
                        fullWidth
                        value={categoryFilter}
                        onChange={(e) => setCategoryFilter(e.target.value)}
                        displayEmpty
                        variant="outlined"
                    >
                        <MenuItem value="">All Categories</MenuItem>
                        <MenuItem value="Evap">Evap</MenuItem>
                        <MenuItem value="SCM">SCM</MenuItem>
                        <MenuItem value="Creams">Creams</MenuItem>
                        <MenuItem value="Powders">Powders</MenuItem>
                        <MenuItem value="Cheese">Cheese</MenuItem>
                        <MenuItem value="RTD">RTD</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Select
                        fullWidth
                        value={typeOfVisitFilter}
                        onChange={(e) => setTypeOfVisitFilter(e.target.value)}
                        displayEmpty
                        variant="outlined"
                    >
                        <MenuItem value="">All Types of Visits</MenuItem>
                        <MenuItem value="Hypermarket">Hypermarket</MenuItem>
                        <MenuItem value="Supermarket">Supermarket</MenuItem>
                        <MenuItem value="Self Service">Self Service</MenuItem>
                        <MenuItem value="Large Grocery">Large Grocery</MenuItem>
                        <MenuItem value="Small Grocery">Small Grocery</MenuItem>
                        <MenuItem value="Shops">Shops</MenuItem>
                        <MenuItem value="Wholesale">Wholesale</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        fullWidth
                        label="Search"
                        variant="outlined"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} display="flex" alignItems="center">
                    <Box sx={{ position: 'relative' }}>
                        <ReactDatePicker
                            selected={startDate}
                            onChange={(update) => { setDateRange(update); }}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            isClearable
                            placeholderText="Select date range"
                            dateFormat="yyyy-MM-dd"
                            wrapperClassName="datePicker"
                            customInput={
                                <Box sx={{ padding: '16px', paddingRight: '32px', borderRadius: '4px', border: '1px solid #ccc', bgcolor: 'white', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                    {startDate && endDate ? `${startDate.toISOString().split('T')[0]} - ${endDate.toISOString().split('T')[0]}` : 'Select date range'}
                                </Box>
                            }
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end" alignItems="center">
                    <Button
                        variant="outlined"
                        color="success"
                        onClick={handleDownload}
                        sx={{ mt: 2, padding: '10px 24px', fontSize: '1rem', alignItems: 'center', display: 'flex' }} // Adjusted properties for size and centering
                    >
                        Download
                    </Button>
                </Grid>
            </Grid>
            <div style={{ width: '100%' }}>
                <DataGrid
                    rows={visits}
                    columns={columns}
                    page={page}
                    pageSize={pageSize}
                    rowCount={rowCount}
                    pagination
                    paginationMode="server"
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    onPaginationModelChange={(params) => {
                        setPage(params.page);
                        setPageSize(params.pageSize);
                    }}
                    loading={loading}
                    onRowClick={(params) => {
                        navigate(`/visit/${params.row.id}`);
                    }}
                    disableSelectionOnClick
                    autoHeight
                    pageSizeOptions={[10, 25, 50]}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-root': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#f5f5f5',
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            display: 'none',
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeader:focus-within': {
                            outline: 'none',
                        }
                    }}
                />
            </div>

            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this visit?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteVisit} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Visits;
