// src/providers/ToastProvider.js
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContext = React.createContext();

export const ToastProvider = ({ children }) => {
    const notify = (message, options = {}) => {
        toast(message, options);
    };

    return (
        <ToastContext.Provider value={notify}>
            {children}
            <ToastContainer />
        </ToastContext.Provider>
    );
};

export const useToast = () => React.useContext(ToastContext);
