import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_API_URL } from '../constants';

const AuthGuard = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const token = Cookies.get('adminToken');
            if (!token) {
                navigate('/login');
                return;
            }
            try {
                const response = await axios.post(BASE_API_URL + '/verifyAdminToken', { token });
                if (!response.data.success) {
                    navigate('/login');
                }
            } catch (error) {
                navigate('/login');
            }
        };

        checkAuth();
    }, [navigate]);

    return <>{children}</>;
};

export default AuthGuard;
