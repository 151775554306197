import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Divider, Typography } from '@mui/material';
import { Menu as MenuIcon, Dashboard as DashboardIcon, People as PeopleIcon, Checklist as ChecklistIcon, Leaderboard as LeaderboardIcon, Logout as LogoutIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { CurrentUserContext } from '../providers/CurrentUserContext';
import Cookies from 'js-cookie';

const Navbar = () => {
  const currentUser = useContext(CurrentUserContext);
  const navigate = useNavigate();

  const getFirstName = (fullName) => {
    if (!fullName) return 'Guest';
    return fullName.split(' ')[0];
  };

  const handleLogout = () => {
    Cookies.remove('adminToken');
    navigate('/login');
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/dashboard' },
    { text: 'Visits', icon: <ChecklistIcon />, link: '/visits' },
    { text: 'Users', icon: <PeopleIcon />, link: '/users' },
    { text: 'Leaderboard', icon: <LeaderboardIcon />, link: '/leaderboard' },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        display: { xs: 'none', sm: 'block' },
        [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
      }}
    >
      <Box sx={{ textAlign: 'center', p: 2 }}>
        <img src="/logo-fc-full.svg" alt="Logo" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
      </Box>
      <Divider />
      <Typography variant="h6" component="h6" sx={{ textAlign: 'center', my: 2 }}>
        Welcome, {getFirstName(currentUser ? currentUser.name : 'Guest')}
      </Typography>
      <Divider />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {menuItems.map((item) => (
            <ListItem button key={item.text} component={Link} to={item.link}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
          <ListItem button onClick={handleLogout}>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default Navbar;
