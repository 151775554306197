// src/CurrentUserContext.js
import React, { createContext, useState, useEffect } from 'react';
import apiClient from '../helpers/apiClient';
import Cookies from 'js-cookie';

export const CurrentUserContext = createContext();

export const CurrentUserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const fetchCurrentUser = async () => {
            const adminToken = Cookies.get('adminToken');
            if (adminToken) {
                try {
                    const response = await apiClient.get('/admin/users/getCurrentUser');
                    setCurrentUser(response.data.user);
                } catch (error) {
                    console.error('Error fetching current user:', error);
                }
            }
        };
        fetchCurrentUser();
    }, []);

    return (
        <CurrentUserContext.Provider value={currentUser}>
            {children}
        </CurrentUserContext.Provider>
    );
};
