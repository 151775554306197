import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../helpers/apiClient';
import { Container, Switch } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { useToast } from '../providers/ToastProvider';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sortModel, setSortModel] = useState([
        {
            field: 'created_at',
            sort: 'asc',
        },
    ]);

    const navigate = useNavigate();
    const notify = useToast();

    useEffect(() => {
        document.title = 'Users | Perfect Store Admin';
        fetchUsers();
    }, [page, pageSize, sortModel]);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await apiClient.get(`/admin/users/getUsers`, {
                params: {
                    page: page + 1,
                    perPage: pageSize,
                    sortBy: sortModel[0]?.field,
                    sortOrder: sortModel[0]?.sort,
                },
            });
            setUsers(response.data.data);
            setRowCount(response.data.total);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleToggleApproved = async (userId, currentStatus) => {
        try {
            await apiClient.post(`/admin/users/updateApprovedStatus/${userId}`, { approved: !currentStatus });
            notify('User ' + (currentStatus ? 'deactivated' : 'activated') + ' successfully', { type: 'success' });
            fetchUsers(); // Re-fetch users after updating
        } catch (error) {
            console.error('Error updating user status:', error);
        }
    };

    const handleRowClick = (params) => {
        navigate(`/visits?username=${params.row.name}`);
    };

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 2 },
        { field: 'department', headerName: 'Department', flex: 1 },
        { field: 'role', headerName: 'Role', flex: 1 },
        {
            field: 'approved',
            headerName: 'Approved?',
            flex: 1,
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={() => handleToggleApproved(params.row.id, params.value)}
                    color="primary"
                />
            )
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            flex: 1,
            valueFormatter: (params) => {
                if (params.value) {
                    return format(parseISO(params.value), 'yyyy-MM-dd');
                } else {
                    return '';
                }
            },
        }
    ];

    return (
        <Container maxWidth={false} sx={{ mt: 4 }}>
            <div style={{ width: '100%' }}>
                <DataGrid
                    rows={users}
                    columns={columns}
                    page={page}
                    pageSize={pageSize}
                    rowCount={rowCount}
                    pagination
                    paginationMode="server"
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    onPaginationModelChange={(params) => {
                        setPage(params.page);
                        setPageSize(params.pageSize);
                    }}
                    loading={loading}
                    disableSelectionOnClick
                    autoHeight
                    onRowClick={handleRowClick}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-root': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#f5f5f5',
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            display: 'none',
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeader:focus-within': {
                            outline: 'none',
                        }
                    }}
                />
            </div>
        </Container>
    );
};

export default Users;
