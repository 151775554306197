import React, { useEffect, useState } from 'react';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Collapse,
    Paper,
    Box,
    Typography,
    IconButton,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    TrendingUp as TrendingUpIcon,
    TrendingDown as TrendingDownIcon,
    Remove as RemoveIcon,
} from '@mui/icons-material';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import apiClient from '../helpers/apiClient';
import { subMonths } from 'date-fns';

const getTrendIcon = (value, previousValue) => {
    if (previousValue === undefined || previousValue === null) {
        return (
            <Tooltip title="No previous data available">
                <RemoveIcon color="action" />
            </Tooltip>
        );
    }
    const difference = value - previousValue;
    const trend = difference > 0 ? 'up' : difference < 0 ? 'down' : 'same';
    const trendDescription = `Compared to ${previousValue} in the previous period`;
    switch (trend) {
        case 'up':
            return (
                <Tooltip title={trendDescription}>
                    <TrendingUpIcon color="success" />
                </Tooltip>
            );
        case 'down':
            return (
                <Tooltip title={trendDescription}>
                    <TrendingDownIcon color="error" />
                </Tooltip>
            );
        case 'same':
            return (
                <Tooltip title={trendDescription}>
                    <RemoveIcon color="action" />
                </Tooltip>
            );
        default:
            return null;
    }
};

const CollapsibleRow = ({ row }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow
                onClick={() => setOpen(!open)}
                sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'grey.100' }, fontWeight: 'bold', fontSize: '1.1rem' }}
            >
                <TableCell>{row.market}</TableCell>
                <TableCell>{row.perfectScore.value} {getTrendIcon(row.perfectScore.value, row.perfectScore.previousValue)}</TableCell>
                <TableCell>{row.sosTargets.value} {getTrendIcon(row.sosTargets.value, row.sosTargets.previousValue)}</TableCell>
                <TableCell>{row.mustStockList.value} {getTrendIcon(row.mustStockList.value, row.mustStockList.previousValue)}</TableCell>
                <TableCell>{row.onShelfAvailability.value} {getTrendIcon(row.onShelfAvailability.value, row.onShelfAvailability.previousValue)}</TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2}>
                            <Typography variant="h6" gutterBottom component="div">
                                Categories
                            </Typography>
                            <Table size="small" aria-label="categories">
                                <TableHead sx={{ bgcolor: 'grey.200' }}>
                                    <TableRow>
                                        {['Category', 'Perfect Score', 'SOS Targets', 'Must Stock List', 'On Shelf Availability'].map((headCell) => (
                                            <TableCell key={headCell}>
                                                {headCell}
                                            </TableCell>
                                        ))}
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.categories.map((categoryRow, index) => (
                                        <CollapsibleCategoryRow key={index} row={categoryRow} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const CollapsibleCategoryRow = ({ row }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow
                onClick={() => setOpen(!open)}
                sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'grey.50' }, fontWeight: 'bold', fontSize: '1rem' }}
            >
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.perfectScore.value} {getTrendIcon(row.perfectScore.value, row.perfectScore.previousValue)}</TableCell>
                <TableCell>{row.sosTargets.value} {getTrendIcon(row.sosTargets.value, row.sosTargets.previousValue)}</TableCell>
                <TableCell>{row.mustStockList.value} {getTrendIcon(row.mustStockList.value, row.mustStockList.previousValue)}</TableCell>
                <TableCell>{row.onShelfAvailability.value} {getTrendIcon(row.onShelfAvailability.value, row.onShelfAvailability.previousValue)}</TableCell>
                <TableCell>
                    <IconButton aria-label="expand row" size="small">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={4}>
                            <Typography variant="h6" gutterBottom component="div">
                                Visit Types
                            </Typography>
                            <Table size="small" aria-label="visit types">
                                <TableHead sx={{ bgcolor: 'grey.100' }}>
                                    <TableRow>
                                        {['Visit Type', 'Perfect Score', 'SOS Targets', 'Must Stock List', 'On Shelf Availability'].map((headCell) => (
                                            <TableCell key={headCell}>
                                                {headCell}
                                            </TableCell>
                                        ))}
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.visitTypes.map((visitTypeRow, index) => (
                                        <TableRow key={index} sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'grey.100' }, fontSize: '0.9rem' }}>
                                            <TableCell>{visitTypeRow.visitType}</TableCell>
                                            <TableCell>{visitTypeRow.perfectScore.value} {getTrendIcon(visitTypeRow.perfectScore.value, visitTypeRow.perfectScore.previousValue)}</TableCell>
                                            <TableCell>{visitTypeRow.sosTargets.value} {getTrendIcon(visitTypeRow.sosTargets.value, visitTypeRow.sosTargets.previousValue)}</TableCell>
                                            <TableCell>{visitTypeRow.mustStockList.value} {getTrendIcon(visitTypeRow.mustStockList.value, visitTypeRow.mustStockList.previousValue)}</TableCell>
                                            <TableCell>{visitTypeRow.onShelfAvailability.value} {getTrendIcon(visitTypeRow.onShelfAvailability.value, visitTypeRow.onShelfAvailability.previousValue)}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const Dashboard = () => {
    const [dateRange, setDateRange] = useState([subMonths(new Date(), 1), new Date()]);
    const [startDate, endDate] = dateRange;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false); // Add loading state

    useEffect(() => {
        document.title = 'Dashboard | Perfect Store Admin';
        if (startDate && endDate) {
            setLoading(true); // Show loader
            apiClient.get('/admin/dashboard/getSummary', {
                params: {
                    startDate: startDate.toISOString().split('T')[0],
                    endDate: endDate.toISOString().split('T')[0],
                },
            })
                .then(response => {
                    setData(response.data);
                    setLoading(false); // Hide loader
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setLoading(false); // Hide loader
                });
        }
    }, [startDate, endDate]);

    return (
        <Container maxWidth={false} sx={{ mt: 4 }}>
            <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5" component="h2">
                    Market Overview
                </Typography>
                <Box sx={{ position: 'relative' }}>
                    <ReactDatePicker
                        selected={startDate}
                        onChange={(update) => { setDateRange(update); }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        isClearable
                        placeholderText="Select date range"
                        dateFormat="yyyy-MM-dd"
                        wrapperClassName="datePicker"
                        customInput={
                            <Box sx={{ padding: '8px', paddingRight: '32px', borderRadius: '4px', border: '1px solid #ccc', bgcolor: 'white', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                {startDate && endDate ? `${startDate.toISOString().split('T')[0]} - ${endDate.toISOString().split('T')[0]}` : 'Select date range'}
                            </Box>
                        }
                    />
                </Box>
            </Box>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead sx={{ bgcolor: 'grey.300' }}>
                            <TableRow>
                                {['Market', 'Perfect Score', 'SOS Targets', 'Must Stock List', 'On Shelf Availability'].map((headCell) => (
                                    <TableCell key={headCell}>
                                        {headCell}
                                    </TableCell>
                                ))}
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No data found
                                    </TableCell>
                                </TableRow>
                            ) : (
                                data.map((row, index) => (
                                    <CollapsibleRow key={index} row={row} />
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Container>
    );
};

export default Dashboard;
