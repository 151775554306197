import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Container, TextField, Button, Card, CardContent, Typography, Box, Link } from '@mui/material';
import { BASE_API_URL } from '../constants';
import { useToast } from '../providers/ToastProvider';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const notify = useToast();

    useEffect(() => {
        document.title = 'Login | Perfect Store Admin';
        const checkAdminToken = async () => {
            const token = Cookies.get('adminToken');
            if (token) {
                try {
                    const response = await axios.post(BASE_API_URL + '/verifyAdminToken', { token });
                    if (response.data.success) {
                        navigate('/');
                    }
                } catch (error) {
                    console.error('Token verification failed:', error);
                }
            }
        };

        checkAdminToken();
    }, [navigate]);

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            if (!email || !password) {
                notify('Please enter an email and password', { type: 'error' });
                return;
            }
            let passwordEncoded = btoa(password);
            const response = await axios.post(BASE_API_URL + '/login', { email, password: passwordEncoded, admin: true });
            if (response.data.token) {
                Cookies.set('adminToken', response.data.token);
                navigate('/');
            } else {
                notify('Invalid email or password', { type: 'error' });
            }
        } catch (error) {
            notify('Invalid email or password', { type: 'error' });
            console.error('Login failed:', error);
        }
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                backgroundColor: '#f5f5f5',
                textAlign: 'center',
                padding: '0 20px'
            }}
        >
            <Card sx={{ width: '100%', boxShadow: 3, borderRadius: '16px', padding: '20px' }}>
                <CardContent>
                    <Box sx={{ my: 2 }}>
                        <img src="/logo-fc-full.svg" alt="Logo" style={{ width: '250px', height: 'auto' }} />
                    </Box>
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleLogin}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Email"
                            name="email"
                            type="email"
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Password"
                            name="password"
                            type="password"
                            variant="outlined"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            sx={{
                                mt: 2,
                                borderRadius: '24px',
                                padding: '12px 0',
                                textTransform: 'none',
                                fontSize: '16px',
                                ':hover': {
                                    backgroundColor: '#1565c0'
                                }
                            }}
                        >
                            Login
                        </Button>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Link href="#" underline="hover" sx={{ fontSize: '14px' }}>
                            Forgot Password?
                        </Link>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    );
};

export default Login;
