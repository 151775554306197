// src/App.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import { Box, Typography, Divider, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Menu as MenuIcon, Dashboard as DashboardIcon, People as PeopleIcon, Checklist as ChecklistIcon, Leaderboard as LeaderboardIcon, Logout as LogoutIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Visits from './pages/Visits';
import Leaderboard from './pages/Leaderboard';
import VisitDetail from './pages/Visit';
import { CurrentUserProvider } from './providers/CurrentUserContext';
import { ToastProvider } from './providers/ToastProvider';
import Cookies from 'js-cookie';
import AuthGuard from './components/AuthGuard';

const App = () => {
  return (
    <Router>
      <ToastProvider>
        <AppContent />
      </ToastProvider>
    </Router>
  );
};

const AppContent = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleMobileDrawerOpen = () => {
    setIsMobileDrawerOpen(true);
  };

  const handleMobileDrawerClose = () => {
    setIsMobileDrawerOpen(false);
  };

  const handleLogout = () => {
    Cookies.remove('adminToken');
    navigate('/login');
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/dashboard' },
    { text: 'Visits', icon: <ChecklistIcon />, link: '/visits' },
    { text: 'Users', icon: <PeopleIcon />, link: '/users' },
    { text: 'Leaderboard', icon: <LeaderboardIcon />, link: '/leaderboard' },
  ];

  const AppMainContent = () => (
    <Box sx={{ display: 'flex', backgroundColor: isLoginPage ? '#f5f5f5' : 'inherit' }}>
      {!isLoginPage && <Navbar />}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {!isLoginPage && (
          <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h4" component="h1" sx={{ mb: 0, pb: 0 }}>
              {location.pathname === '/dashboard' && 'Dashboard'}
              {location.pathname === '/users' && 'Users'}
              {location.pathname.startsWith('/visit') && location.pathname !== '/visits' && `Visit Detail`}
              {location.pathname === '/visits' && 'Visits'}
              {location.pathname === '/leaderboard' && 'Leaderboard'}
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleMobileDrawerOpen}
              sx={{ display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        )}
        {!isLoginPage && <Divider />}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<AuthGuard><Dashboard /> </AuthGuard>} />
          <Route path="/users" element={<AuthGuard><Users /> </AuthGuard>} />
          <Route path="/visits" element={<AuthGuard><Visits /> </AuthGuard>} />
          <Route path="/leaderboard" element={<AuthGuard><Leaderboard /> </AuthGuard>} />
          <Route path="/visit/:id" element={<AuthGuard><VisitDetail /> </AuthGuard>} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
        </Routes>
      </Box>
      <Drawer
        variant="temporary"
        open={isMobileDrawerOpen}
        onClose={handleMobileDrawerClose}
        sx={{
          display: { xs: 'block', sm: 'none' },
          [`& .MuiDrawer-paper`]: { boxSizing: 'border-box', width: 240 },
        }}
      >
        <Box sx={{ textAlign: 'center', p: 2 }}>
          <img src="/logo-fc-full.svg" alt="Logo" style={{ width: '150px', height: 'auto' }} />
        </Box>
        <List>
          {menuItems.map((item) => (
            <ListItem button key={item.text} component={Link} to={item.link} onClick={handleMobileDrawerClose}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
          <ListItem button onClick={handleLogout}>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );

  return isLoginPage ? (
    <AppMainContent />
  ) : (
    <CurrentUserProvider>
      <AppMainContent />
    </CurrentUserProvider>
  );
};

export default App;
